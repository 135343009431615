export default {
  SET_MENU(state, data) {
    state.menu = data;
  },
  TOGGLE_SIDEBAR(state) {
    state.sidebar = !state.sidebar;
  },
  SET_AUTH_TOKEN: function(state, token) {
    state.auth_token = token;
  },
  SET_CONFIG: function(state, config) {
    state.config = config;
  },
  SET_AGENT: function(state, agent) {
    state.agent = agent;
  },
  SET_PERMISSIONS: function(state, agent) {
    state.permissions = agent;
  }
};
