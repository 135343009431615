import Vue from "vue";
import Vuex from "vuex";
import authModule from "./modules/auth-module";
import commonModule from "./modules/common-module";
import ecommerceModule from "./modules/ecommerce-module";
Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  modules: {
    authLogic: authModule,
    commonLogic: commonModule,
    ecommerceLogic: ecommerceModule
  }
});
