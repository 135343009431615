import axios from "axios";
import Cookie from "js-cookie";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["X-ORIGIN"] = process.env.VUE_APP_ORIGIN;
if (Cookie.get("token"))
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + Cookie.get("token");

export default axios;
