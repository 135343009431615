<template>
  <section class="wrap">
    <header class="header px">
      <div
        v-if="title || subtitle"
        class="title-container"
      >
        <span
          v-if="title"
          class="title is-size-4-mobile"
        >{{ title }}</span>
        <span
          v-if="subtitle && loading"
          class="heading mb-0"
        >
          <b-skeleton
            width="80px"
            height="12px"
            animated
          />
        </span>
        <span
          v-else-if="subtitle"
          class="heading mb-0"
        >{{ subtitle }}
          <b-dropdown
            v-if="extraSubtitle && extraSubtitle.length > 0"
            aria-role="list"
            position="is-bottom-right"
            :triggers="['hover']"
          >
            <b-tag
              slot="trigger"
              size="is-small"
              type="is-info is-light"
              rounded
            >
              +{{ extraSubtitle.length }}
              <b-icon
                icon="chevron-down"
                size="is-small"
                class="is-clickable ml-1"
              />
            </b-tag>

            <template v-for="ext in extraSubtitle">
              <b-dropdown-item
                :key="ext"
                :focusable="false"
                custom
                aria-role="listitem"
              >
                {{ ext.formatted }}
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </span>
      </div>
      <div
        v-if="hasActions"
        class="ml-4"
      >
        <slot name="actions" />
      </div>
    </header>
    <div
      class="body px pb-5"
      :class="{ fw: isFullWidth }"
    >
      <slot />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    subtitle: {
      type: String,
      default: null
    },
    extraSubtitle: {
      type: Array,
      default: null
    },
    hasActions: {
      type: Boolean,
      default: false
    },
    isFullWidth: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      title: "getTitle"
    })
  }
};
</script>
