export default {
  list: [
    {
      label: "brands_count",
      value: "{brands_count}"
    },
    {
      label: "product",
      value: "{product}"
    },
    {
      label: "category",
      value: "{category}"
    },
    {
      label: "brand",
      value: "{brand}"
    },
    {
      label: "site",
      value: "{site}"
    },
    {
      label: "shipping_method",
      value: "{shipping_method}"
    },
    {
      label: "free_shipping_from",
      value: "{free_shipping_from}"
    },
    {
      label: "trustedshops_guarantee",
      value: "{trustedshops_guarantee}"
    }
  ],
};
