var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"tag":"div","vid":_vm.vid,"name":_vm.$attrs.label,"rules":_vm.rules,"mode":_vm.validationMode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',_vm._b({class:{ 'is-empty': _vm.innerValue && _vm.innerValue.length == 0 && errors[0] },attrs:{"expanded":_vm.expanded,"disabled":_vm.disabled,"type":{
      'is-default': _vm.disabled,
      'is-danger': errors[0],
      'is-empty':
        !_vm.innerValue || (_vm.innerValue && _vm.innerValue.length == 0) || errors[0]
    },"message":_vm.hideDetails ? '' : errors}},'b-field',_vm.$attrs,false),[_c('b-select',_vm._b({attrs:{"placeholder":"Select a subject","size":_vm.size,"disabled":_vm.disabled,"expanded":""},on:{"input":function (val) { return _vm.$emit('input', val); }},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'b-select',_vm.$attrs,false),[_c('option',{attrs:{"hidden":_vm.placeholderOptionHidden,"disabled":_vm.placeholderOptionDisabled,"value":""}},[_vm._v(" "+_vm._s(_vm.placeholder || _vm.$t("choose"))+" ")]),_vm._t("default")],2)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }