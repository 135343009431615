<template>
  <div class="has-text-centered mt-6 py-5">
    <b-icon
      :icon="icon"
      size="is-small"
      class="has-text-light"
      style="font-size: 80px;"
    />
    <p
      v-if="showText"
      class="mt-2"
    >
      {{ text || $t("no_result_found") }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "account-supervisor"
    },
    customSize: {
      type: String,
      default: "mdi-48px"
    },
    showText: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      default: null
    }
  }
};
</script>
