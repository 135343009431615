export default {
  SET_BAR_IMAGE(state, payload) {
    state.barImage = payload;
  },
  SET_DRAWER(state, payload) {
    state.navigation = payload;
  },
  SET_SCRIM(state, payload) {
    state.barColor = payload;
  },
  UPDATE_DRAWER_LOADING: function(state, bool) {
    state.drawerLoading = bool;
  },
  UPDATE_TITLE(state, title) {
    state.title = title;
  },
  OPEN_RIGHT_DRAWER(state, drawer_options) {
    if (drawer_options.level === undefined || drawer_options.level === "main")
      state.right_drawer = drawer_options;
    else if (drawer_options.level === 1) {
      state.right_drawer_child1 = drawer_options;
    } else {
      state.right_drawer_child2 = drawer_options;
    }
  },
  CLOSE_RIGHT_DRAWER(state, level) {
    if (level === "main") {
      state.right_drawer = {
        show: false,
        content: "",
        width: "medium",
        options: {}
      };
    } else if (level === 1) {
      state.right_drawer_child1 = {
        show: false,
        content: "",
        width: "medium",
        options: {}
      };
    } else if (level === 2) {
      state.right_drawer_child2 = {
        show: false,
        content: "",
        width: "medium",
        options: {}
      };
    }
  },
  OPEN_DIALOG(state, dialog_options) {
    state.dialog = dialog_options;
  },
  CLOSE_DIALOG(state) {
    state.dialog = {
      width: 500,
      show: false,
      loading: false,
      persistent: false,
      fullscreen: false,
      hide_overlay: true,
      content: "",
      transition: ""
    };
  },

  OPEN_SNACKBAR(state, snackbar_options) {
    state.snackbar = snackbar_options;
  }
};
