export default {
  created() {
    let that = this;

    that.escListenet = document.addEventListener("keyup", function(evt) {
      if (evt.keyCode === 27) {
        that.close();
      }
    });
  }
};
