import Vue from "vue";
import {
  required,
  confirmed,
  length,
  email,
  max,
  min,
  max_value,
  min_value,
  numeric
} from "vee-validate/dist/rules";
// import * as rules from 'vee-validate/dist/rules'
import { extend } from "vee-validate";
import Axios from "@/plugins/axios";
import { ValidationObserver } from "vee-validate";

import debounce from "debounce";

// setInteractionMode("eager");

Vue.component("ValidationObserver", ValidationObserver);

// Object.keys(rules).forEach(rule => {
//   extend(rule, rules[rule])
// })

extend("required", {
  ...required,
  message: "Ce champ est requis"
});

extend("email", {
  ...email,
  message: "Ce champ doit être un email valide"
});

extend("confirmed", {
  ...confirmed,
  message: "Ce champ de confirmation ne correspond pas"
});

extend("max", {
  ...max,
  message: "Ce champ doit comporter au plus {length} caractères"
});

extend("min", {
  ...min,
  message: "Ce champ doit comporter au moins {length} caractères"
});

extend("max_value", {
  ...max_value,
  message: "Ce champ doit être moins que {max}"
});

extend("min_value", {
  ...min_value,
  message: "Ce champ doit être plus que {min}"
});

extend("length", {
  ...length,
  message: "Ce champ doit avoir {length} options"
});

extend("numeric", {
  ...numeric,
  message: "Ce champ doit être numerique"
});

extend("valid_email", {
  message: "Cet e-mail existe déjà",
  validate: (value, args) => {
    if (value) {
      return Axios.head("users/" + args + "/" + value + "/check")
        .then(() => {
          return true;
        })
        .catch(e => {
          const code = parseInt(e.response && e.response.status);
          if (code === 409) {
            return false;
          }
        });
    } else {
      return false;
    }
  }
});

extend("valid_password", {
  message: "Ce champ est incorrect",
  validate(value) {
    if (value) {
      return Axios.head("me/password/" + value + "/check")
        .then(() => {
          return {
            valid: true
          };
        })
        .catch(e => {
          const code = parseInt(e.response && e.response.status);
          if (code === 409) {
            return {
              valid: false
            };
          }
        });
    } else {
      return false;
    }
  }
});

extend("valid_blog_permalink", {
  message: "Ce permalink existe déjà",
  validate(value, args) {
    if (value) {
      // site_id/permalink
      return Axios.head("blog/permalink/" + args)
        .then(() => {
          return {
            valid: true
          };
        })
        .catch(e => {
          const code = parseInt(e.response && e.response.status);
          if (code === 409)
            return {
              valid: false
            };
        });
    } else {
      return false;
    }
  }
});

extend("valid_voucher", {
  message: "Ce code existe déjà",
  validate(value, args) {
    if (value && args[0] != "undefined") {
      return Axios.head(
        `ecommerce/vouchers/${encodeURIComponent(
          value.split("/").join(" ")
        )}/${args}/check`
      )
        .then(() => {
          return {
            valid: true
          };
        })
        .catch(e => {
          const code = parseInt(e.response && e.response.status);
          if (code === 409)
            return {
              valid: false
            };
        });
    } else {
      return false;
    }
  }
});

extend("valid_brand_permalink", {
  message: "Ce permalink existe déjà",
  validate(value, args) {
    if (value) {
      return Axios.head("brands/permalink/" + args)
        .then(() => {
          return {
            valid: true
          };
        })
        .catch(e => {
          const code = parseInt(e.response && e.response.status);
          if (code === 409)
            return {
              valid: false
            };
        });
    } else {
      return false;
    }
  }
});

extend("valid_pack_permalink", {
  message: "Ce permalink existe déjà",
  validate(value, args) {
    if (value) {
      return Axios.head("/packs/permalink/" + value + "/" + args)
        .then(() => {
          return {
            valid: true
          };
        })
        .catch(e => {
          const code = parseInt(e.response && e.response.status);
          if (code === 409)
            return {
              valid: false
            };
        });
    } else {
      return false;
    }
  }
});

extend("valid_product_permalink", {
  message: "Ce permalink existe déjà",
  validate(value, args) {
    if (value) {
      return Axios.head("catalog/product/permalink/" + value + "/" + args)
        .then(() => {
          return {
            valid: true
          };
        })
        .catch(e => {
          const code = parseInt(e.response && e.response.status);
          if (code === 409)
            return {
              valid: false
            };
        });
    } else {
      return false;
    }
  }
});

extend("valid_category_permalink", {
  message: "Ce permalink existe déjà",
  validate(value, args) {
    if (value) {
      return Axios.head(`/categories/permalink/${value}/${args}`)
        .then(() => {
          return {
            valid: true
          };
        })
        .catch(e => {
          const code = parseInt(e.response && e.response.status);
          if (code === 409)
            return {
              valid: false
            };
        });
    } else {
      return false;
    }
  }
});

extend("valid_blog_category_permalink", {
  message: "Ce permalink existe déjà",
  validate(value, args) {
    if (value) {
      return Axios.head(`/blog/categories/permalink/${value}/${args}`)
        .then(() => {
          return {
            valid: true
          };
        })
        .catch(e => {
          const code = parseInt(e.response && e.response.status);
          if (code === 409)
            return {
              valid: false
            };
        });
    } else {
      return false;
    }
  }
});
