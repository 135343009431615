export default {
  // Store location
  SET_SELECTED_LOCATION(state, location) {
    state.selectedLocation = location;
  },
  SET_STORES(state, stores) {
    state.stores = stores;
  },
  SET_SELECTED_STORE(state, store) {
    state.selectedStore = store;
  },
  UPDATE_ADDRESSES(state, store) {
    state.addresses = store;
  }
};
