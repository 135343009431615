import Vue from "vue";
import App from "./App.vue";
import Buefy from "buefy";
import router from "./router";

import device from "vue-device-detector";
import { abilitiesPlugin } from "@casl/vue";
import { Ability } from "@casl/ability";
import jwt from "jsonwebtoken";
import Cookie from "js-cookie";
import VueScrollTo from "vue-scrollto";

import Page from "@/layout/Page";
import Card from "@/layout/Card";
import BSelectValidation from "@/components/forms/elements/BSelectWithValidation";
import BInputValidation from "@/components/forms/elements/BInputWithValidation";
import BDatepickerValidation from "@/components/forms/elements/BDatepickerWithValidation";
import BAutocompleteValidation from "@/components/forms/elements/BAutocompleteWithValidation";
import Pagination from "@/components/forms/elements/Pagination";
import EmptyBlock from "@/components/EmptyBlock";

//Import Froala Editor
import 'froala-editor/js/plugins.pkgd.min.js';
//Import third party plugins
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/font_awesome.min';
import 'froala-editor/js/third_party/spell_checker.min';
import 'froala-editor/js/third_party/image_tui.min';
// Import Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)

// Importing styles
import "./assets/scss/_overrides.scss";
import "./assets/scss/app.scss";

import i18n from "./i18n";

import Axios from "@/plugins/axios";
import helpers from "@/plugins/helpers";
import "./plugins/vee-validate";
import "./plugins/moment";

Vue.config.productionTip = false;

Vue.use(device);
Vue.use(VueScrollTo, {
  container: "main.main",
  duration: 500,
  easing: "ease-out",
  offset: -76, // = $$navbar-height + $padding-sm
  force: true,
  cancelable: false,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});
Vue.use(Buefy, {
  defaultIconPack: "mdi",
  defaultStatusIcon: false,
  defaultFirstDayOfWeek: 1,
  defaultInputAutocomplete: "off",
  defaultFieldLabelPosition: "on-border",
  defaultDatepickerNearbySelectableMonthDays: true,
  defaultDatepickerMobileNative: false,
  defaultTimepickerMobileNative: false
});
Vue.prototype.$axios = Axios;
// Bus Config
Vue.prototype.$bus = new Vue();

Vue.component("Page", Page);
Vue.component("Card", Card);
Vue.component("BSelectValidation", BSelectValidation);
Vue.component("BInputValidation", BInputValidation);
Vue.component("BDatepickerValidation", BDatepickerValidation);
Vue.component("BAutocompleteValidation", BAutocompleteValidation);
Vue.component("Pagination", Pagination);
Vue.component("EmptyBlock", EmptyBlock);

// Check agent session
let token = Cookie.get("token");
let decoded = jwt.decode(token);

if (token && decoded) {
  let perms = decoded.perms;
  let signature = decoded.signature ? decoded.signature : "Agent";
  Vue.prototype.$signature = signature;

  let ability_perms = Object.keys(perms).map(el => {
    let splitted_perms = perms[el].split(":");

    let subject = splitted_perms[0];
    let actions = splitted_perms[1];
    return { action: actions, subject: subject };
  });
  const ability = new Ability(ability_perms);
  Vue.use(abilitiesPlugin, ability);
} else {
  Cookie.remove("token");
  const ability = new Ability([]);
  Vue.use(abilitiesPlugin, ability);
}
Vue.mixin(helpers);
const store = require("./store/index").default;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
