<template>
  <div class="pagination-container">
    <b-select
      v-if="!noPerPages"
      v-model="perPageLocal"
      placeholder="Select a value"
      size="is-small"
    >
      <template v-for="option in perPages">
        <option
          v-if="typeof option != 'object'"
          :key="option"
          :value="option"
        >
          {{ option }}
        </option>
        <option
          v-else
          :key="option.key"
          :value="option.key"
        >
          {{
            option.value
          }}
        </option>
      </template>
    </b-select>

    <span v-else></span>

    <b-pagination
      v-model="current"
      class="is-justify-content-flex-end"
      :total="total"
      :simple="simple"
      size="is-small"
      :per-page="perPageLocal == 'all' ? total : perPageLocal"
    />
  </div>
</template>

<script>
export default {
  props: {
    perPages: { type: Array, default: () => [5, 20, 50] },
    perPage: { type: [Number, String], required: true },
    total: { type: Number, required: true },
    currentPage: { type: Number, required: true },
    simple: { type: Boolean, default: true },
    noPerPages: { type: Boolean, default: false },
  },
  data() {
    return {
      current: null,
      perPageLocal: null
    };
  },
  watch: {
    perPageLocal(newVal) {
      this.$emit("update-per-page", newVal);
    },
    current(newVal) {
      this.$emit("update-current-page", newVal);
    }
  },
  created() {
    this.current = this.currentPage;
    this.perPageLocal = this.perPage;
  }
};
</script>
