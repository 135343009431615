import Vue from "vue";
import VueI18n from "vue-i18n";
import Cookie from "js-cookie";
// import fr from 'vuetify/lib/locale/fr'

Vue.use(VueI18n);

const messages = {
  fr: {
    ...require("@/langs/fr.json")
  },
  en: {
    ...require("@/langs/en.json")
  },
  it: {
    ...require("@/langs/it.json")
  }
};

const lang = Cookie.get("lang");

if (!lang) Cookie.set("lang", "en");

export default new VueI18n({
  locale: lang || "en",
  fallbackLocale: "en",
  messages
});
