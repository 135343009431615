<template>
  <div id="app">
    <defaultLayout />
  </div>
</template>

<script>
import defaultLayout from "./layout/default";
export default {
  name: "App",
  components: {
    defaultLayout
  },
  mounted() {
    if (process.env.VUE_APP_PUSHER_KEY && process.env.VUE_APP_PUSHER_CLUSTER) {
      const Pusher = require("pusher-js");
      let pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER
      });
      let channel = pusher.subscribe("admin");
      channel.bind("reload", () => {
        this.$buefy.dialog.confirm({
          message: this.$t("new_version_available"),
          type: "is-info",
          hasIcon: true,
          trapFocus: true,
          confirmText: this.$t("reload"),
          cancelText: this.$t("cancel"),
          onConfirm: () => this.reloadPage()
        });
      });
    }
  },
  methods: {
    reloadPage() {
      localStorage.clear();
      window.location.href = window.location.href;
    }
  }
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
</style>
