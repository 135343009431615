export default {
  numeric: [
    { value: "is", label: "is" },
    { value: "less", label:  "is <" },
    { value: "greater", label: "is >" }
  ],
  char: [
    { value: "is", label: "is" },
    { value: "contains", label: "contains" },
    { value: "starts", label: "starts with" },
    { value: "ends", label: "ends with" }
  ]
};
