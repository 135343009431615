var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"tag":"div","vid":_vm.vid,"name":_vm.$attrs.name || _vm.label,"rules":_vm.rules,"mode":_vm.validationMode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',_vm._b({attrs:{"label":_vm.label,"addons":_vm.addons,"disabled":_vm.disabled,"type":{
      'is-default': _vm.disabled,
      'is-danger': errors[0],
    },"message":_vm.hideDetails ? null : errors || _vm.message}},'b-field',_vm.$attrs,false),[_c('b-autocomplete',{key:("autocomplete_" + _vm.key),class:{ 'fit-content': _vm.fitContent },style:(_vm.autocompleteStyle),attrs:{"disabled":_vm.disabled,"rounded":_vm.rounded,"size":_vm.size,"placeholder":_vm.placeholder || _vm.$t('choose'),"data":_vm.filteredDataObj,"field":_vm.itemText,"expanded":_vm.expanded,"open-on-focus":"","clearable":_vm.clearable},on:{"select":_vm.select},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.type == 'phone')?[_vm._v(" "+_vm._s(props.option.country + " (" + props.option.code + ")")+" ")]:(_vm.type == 'country')?[_vm._v(" "+_vm._s(props.option.country)+" ")]:(_vm.type == 'category')?[_c('span',{class:props.option.itemClass},[_vm._v(" "+_vm._s(props.option.label)+" ")])]:_vm._t("item",[_vm._v(" "+_vm._s(props.option[_vm.itemText])+" ")],{"props":props})]}}],null,true),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[(_vm.hasAddButton)?_c('template',{slot:"footer"},[_vm._t("footer",[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.filteredDataObj.length == 0),expression:"filteredDataObj.length == 0"}],staticClass:"is-clickable has-text-primary",on:{"click":function($event){return _vm.$emit('click-add', _vm.search)}}},[_vm._v(" "+_vm._s(_vm.$t("add"))+" ")])])],2):_vm._e(),_c('template',{slot:"empty"},[_vm._v(" "+_vm._s(_vm.$t("no_result_found"))+" ")])],2),_vm._t("addon",null,{"props":{
        isEmpty: _vm.filteredDataObj.length == 0,
        search: _vm.search
      }})],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }