<template>
  <div class="card">
    <slot name="header" />
    <div
      v-if="title"
      class="is-flex is-align-items-center is-justify-content-space-between px pt-4"
      :class="headerClass"
    >
      <p class="is-size-4">
        {{ title }}
      </p>
      <div class="is-flex is-align-items-center">
        <slot name="actions" />
      </div>
    </div>
    <div
      class="card-content"
      :class="`${contentClass}`"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    headerClass: {
      type: String,
      default: ""
    },
    contentClass: {
      type: String,
      default: "px pt-4"
    }
  }
};
</script>
