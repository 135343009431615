export default {
  getBarColor: state => state.barColor,
  getBarImage: state => state.barImage,
  drawerLoading: state => state.drawerLoading,
  getTitle: state => state.title,
  getNavigation: state => state.navigation,
  getRightDrawer: state => state.right_drawer,
  getRightDrawerChild1: state => state.right_drawer_child1,
  getRightDrawerChild2: state => state.right_drawer_child2,
  getDialog: state => state.dialog,
  getSnackbar: state => state.snackbar
};
