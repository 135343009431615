var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAuth)?_c('div',{staticClass:"layout"},[_c('b-sidebar',{staticClass:"layout-sidebar",attrs:{"position":"static","mobile":_vm.mobile,"expand-on-hover":_vm.expandOnHover,"reduce":_vm.reduce,"open":""}},[_c('div',{staticClass:"\n        logo\n        is-flex is-justify-content-space-between is-align-items-center\n      "},[(_vm.reduce)?_c('img',{attrs:{"src":"/icon.png","alt":"Nutriweb","width":"40","height":"40"}}):_c('img',{attrs:{"src":"/img/logo.svg","alt":"Nutriweb","width":"134","height":"46"}}),(_vm.$device.mobile)?_c('span',{on:{"click":_vm.changeSideBar}},[_c('b-icon',{attrs:{"icon":"close","type":"is-white"}})],1):_vm._e()]),_c('b-menu',{staticClass:"is-custom-mobile mb-4"},[_vm._l((_vm.items),function(item){return [(
            item.children &&
              (item.hasOwnProperty('condition') ? item.condition : true)
          )?_c('b-menu-list',{key:item.title},[_c('b-menu-item',{attrs:{"label":item.title,"active":_vm.$route.path.includes(item.group),"expanded":_vm.$route.path.includes(item.group),"icon":item.icon},scopedSlots:_vm._u([{key:"label",fn:function(props){return [_vm._v(" "+_vm._s(item.title)+" "),_c('b-icon',{staticClass:"is-pulled-right",attrs:{"icon":props.expanded ? 'chevron-down' : 'chevron-up'}})]}}],null,true)},[_vm._l((item.children),function(child){return [(
                  child.hasOwnProperty('condition') ? child.condition : true
                )?_c('b-menu-item',{key:child.title,attrs:{"tag":"router-link","active":_vm.$route.path.includes(((item.group) + "/" + (child.to))),"icon":child.icon,"to":((item.group) + "/" + (child.to))}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(child.title)+" "),(child.tag)?_c('b-tag',{staticClass:"c-float-right",attrs:{"type":"is-dark","size":"is-small"}},[_vm._v(_vm._s(child.tag))]):_vm._e()],1)]):_vm._e()]})],2)],1):(item.hasOwnProperty('condition') ? item.condition : true)?_c('b-menu-list',{key:item.title},[_c('b-menu-item',{key:item.title,attrs:{"tag":"router-link","to":item.to,"active":_vm.$route.path == item.to,"label":item.title,"icon":item.icon}})],1):_vm._e()]})],2)],1),_c('main',{staticClass:"main"},[_c('b-navbar',{staticClass:"px",attrs:{"mobile-burger":false}},[_c('template',{slot:"brand"},[_c('span',{on:{"click":_vm.changeSideBar}},[_c('b-icon',{attrs:{"icon":"text","size":"is-medium"}})],1)]),_c('template',{slot:"end"},[_c('b-dropdown',{attrs:{"aria-role":"list","position":"is-bottom-left","append-to-body":""}},[_c('div',{staticClass:"navbar-item pr-0",attrs:{"slot":"trigger"},slot:"trigger"},[_c('div',{staticClass:"mr-2"},[_c('span',{staticClass:"text-dark"},[_c('b',[_vm._v(_vm._s(_vm.$signature))])]),_c('span',{staticClass:"is-block has-text-grey-light is-size-8",staticStyle:{"line-height":"1"}},[_vm._v(_vm._s(_vm.role))])]),_c('img',{attrs:{"src":"/img/avatars/blank.svg"}})]),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.openDrawer('resetAgentPassword', {
                level: 'main',
                width: 'small',
              })}}},[_c('b-icon',{attrs:{"icon":"lock","size":"is-small"}}),_vm._v(" "+_vm._s(_vm.$t("change_password"))+" ")],1),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.signout}},[_c('b-icon',{attrs:{"icon":"logout","type":"is-danger","size":"is-small"}}),_vm._v(" "+_vm._s(_vm.$t("signout"))+" ")],1)],1)],1)],2),(_vm.rightDrawer.show)?_c('Drawer',{attrs:{"width":_vm.rightDrawer.width,"has-child1":_vm.transformparent1,"has-child2":_vm.transformparent2,"level":"main"}},[_c(_vm.rightDrawer.content,{key:_vm.rightDrawer.options.key,tag:"component",attrs:{"options":_vm.rightDrawer.options}})],1):_vm._e(),(_vm.rightDrawerChild1.show)?_c('Drawer',{attrs:{"width":_vm.rightDrawerChild1.width,"has-child1":_vm.transformChild1,"level":1}},[_c(_vm.rightDrawerChild1.content,{tag:"component",attrs:{"options":_vm.rightDrawerChild1.options}})],1):_vm._e(),(_vm.rightDrawerChild2.show)?_c('Drawer',{attrs:{"width":_vm.rightDrawerChild2.width,"level":2}},[_c(_vm.rightDrawerChild2.content,{tag:"component",attrs:{"options":_vm.rightDrawerChild2.options}})],1):_vm._e(),_c('b-modal',{attrs:{"width":420},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('Card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"is-size-5 px py-4"},[_c('b',[_vm._v(_vm._s(_vm.$t("update_shipping_question")))])]),_c('hr',{staticClass:"my-0"})]),_c('ValidationObserver',{ref:"observer",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var handleSubmit = ref.handleSubmit;
return [_c('p',{staticClass:"mb-4"},[_c('b',[_vm._v(_vm._s(_vm.$t('old_value'))+":")]),_vm._v(" "+_vm._s(_vm.summary.shipping.formatted)+" ")]),_c('form',{attrs:{"method":"post","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateShipping)}}},[_c('b-input-validation',{staticClass:"is-flex mb-1",attrs:{"vid":"new_shipping","label":_vm.$t('new_value'),"rules":"required","placeholder":_vm.placeholders.number.discount,"suffix":_vm.summary.currency.symbol},model:{value:(_vm.summary.shipping.new),callback:function ($$v) {_vm.$set(_vm.summary.shipping, "new", $$v)},expression:"summary.shipping.new"}}),_c('b-button',{staticClass:"mr-4",attrs:{"type":"is-success","loading":_vm.loadingShipping,"disabled":_vm.loadingShipping,"tag":"input","native-type":"submit","value":_vm.$t('save')}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")]),_c('b-button',{attrs:{"disabled":_vm.loadingShipping},on:{"click":function($event){_vm.showModal = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)]}}],null,false,2874508961)})],1)],1),_c('router-view')],1)],1):_c('router-view')}
var staticRenderFns = []

export { render, staticRenderFns }