<template>
  <ValidationProvider
    v-slot="{ errors }"
    tag="div"
    :vid="vid"
    :name="$attrs.name || label"
    :rules="rules"
    :mode="validationMode"
  >
    <b-field
      v-bind="$attrs"
      :label="label"
      :addons="addons"
      :disabled="disabled"
      :type="{
        'is-default': disabled,
        'is-danger': errors[0],
      }"
      :message="hideDetails ? null : errors || message"
    >
      <b-autocomplete
        :key="`autocomplete_${key}`"
        v-model="search"
        :disabled="disabled"
        :rounded="rounded"
        :class="{ 'fit-content': fitContent }"
        :style="autocompleteStyle"
        :size="size"
        :placeholder="placeholder || $t('choose')"
        :data="filteredDataObj"
        :field="itemText"
        :expanded="expanded"
        open-on-focus
        :clearable="clearable"
        @select="select"
      >
        <template slot-scope="props">
          <template v-if="type == 'phone'">
            {{ props.option.country + " (" + props.option.code + ")" }}
          </template>
          <template v-else-if="type == 'country'">
            {{ props.option.country }}
          </template>
          <template v-else-if="type == 'category'">
            <span :class="props.option.itemClass">
              {{ props.option.label }}
            </span>
          </template>
          <slot
            v-else
            :props="props"
            name="item"
          >
            {{ props.option[itemText] }}
          </slot>
        </template>
        <template
          v-if="hasAddButton"
          slot="footer"
        >
          <slot name="footer">
            <p
              v-show="filteredDataObj.length == 0"
              class="is-clickable has-text-primary"
              @click="$emit('click-add', search)"
            >
              {{ $t("add") }}
            </p>
          </slot>
        </template>
        <template slot="empty">
          {{ $t("no_result_found") }}
        </template>
      </b-autocomplete>
      <slot
        name="addon"
        :props="{
          isEmpty: filteredDataObj.length == 0,
          search: search
        }"
      />
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider
  },
  props: {
    vid: {
      type: String,
      default: ""
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    data: {
      type: Array,
      required: true
    },
    // must be included in props
    value: {
      type: null,
      default: ""
    },
    field: {
      type: String,
      default: ""
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: [String, Number],
      default: ""
    },
    labelPosition: {
      type: String,
      default: "on-border"
    },
    label: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: ""
    },
    itemText: {
      type: String,
      required: true
    },
    itemText2: {
      type: String,
      default: null
    },
    expanded: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    searchText: {
      type: String,
      default: null
    },
    fitContent: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "100px"
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    hasAddButton: {
      type: Boolean,
      default: false
    },
    validationMode: {
      type: String,
      default: "eager"
    },
    rounded: {
      type: Boolean,
      default: false
    },
    clearOnSelect: {
      type: Boolean,
      default: false
    },
    addons: {
      type: Boolean,
      default: false
    },
    clearOnDataChange: {
      type: Boolean,
      default: true
    },
    hasNull: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    innerValue: null,
    search: "",
    key: ""
  }),
  computed: {
    filteredDataObj() {
      if(this.searchText) {
        return this.data.filter((option) => {
          let searchText = this.searchText.split(".");
          let val = "";
          for (let i = 0; i < searchText.length; i++) {
            const element = searchText[i];
            val += option[element];
          }
          return val && val.toLowerCase().indexOf(this.search.toLowerCase()) != -1;
        });
      }
      return this.data.filter(option => {
        return (
          JSON.stringify(option[this.itemText])
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) != -1 ||
          (this.itemText2 &&
            JSON.stringify(option[this.itemText2])
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) != -1)
        );
      });
    },
    autocompleteStyle() {
      return this.fitContent ? `width: ${this.width}` : ``;
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal, oldVal) {
      if (this.clearOnSelect) {
        if (newVal) {
          this.$emit("input", newVal);
          this.search = "";
        }
        this.key += 1;
      } else {
        if (newVal != oldVal) {
          let value = this.data.find(d => d[this.field] == newVal);
          if (value) this.search = value[this.itemText];
        }
        this.$emit("input", newVal);
        this.$emit("change", newVal);
      }
    },
    search(newVal) {
      if (newVal.length == 0) {
        this.select(null);
      }
    },
    // Handles external model changes.
    value(newVal) {
      if (!this.clearOnSelect) {
        this.innerValue = newVal;
      } else {
        this.innerValue = null;
      }
    },
    data() {
      if (this.clearOnDataChange) {
        this.search = "";
        this.innerValue = null;
      }
    }
  },
  created() {
    if (this.value || this.hasNull) {
      this.innerValue = this.value;
      let value = this.data.find(d => d[this.field] == this.value);
      if (value) this.search = value[this.itemText];
    }
  },
  methods: {
    select(option) {
      if (option && !!this.field.length) {
        let fields = this.field.split(".");
        for (let i = 0; i < fields.length; i++) {
          const f = fields[i];
          option = option[f];
        }
      }
      this.innerValue = option;
    }
  }
};
</script>
