<template>
  <ValidationProvider
    v-slot="{ errors }"
    tag="div"
    :vid="vid"
    :name="$attrs.name || label"
    :rules="rules"
    :mode="validationMode"
  >
    <b-field
      :key="!!prefix || !!suffix"
      v-bind="$attrs"
      :label="label"
      :disabled="disabled"
      :addons="!!prefix || !!suffix"
      :type="{
        'is-default': disabled,
        'is-danger': errors[0] || isError,
        'is-info': type == 'is-info'
      }"
      :message="hideDetails ? null : [...errors, ...message]"
    >
      <p
        v-if="prefix"
        class="control"
      >
        <span
          class="button is-static"
          :class="size"
        >{{ prefix }}</span>
      </p>
      <b-input
        :ref="inputRef"
        v-model="innerValue"
        :expanded="expanded"
        v-bind:tabindex="tabindex"
        :placeholder="placeholder"
        v-bind:size="size"
        :disabled="disabled"
        v-bind="$attrs"
        :type="inputType"
        v-bind:step="step"
        :has-counter="hasCounter"
        @input="emitInput"
        @change.native="emitChange"
        @icon-right-click="emitIconRightClick"
        @blur="emitBlur"
        @focus="emitFocus"
      />
      <p
        v-if="suffix"
        class="control"
      >
        <slot name="suffix">
          <span
            class="button is-static"
            :class="size"
          >{{ suffix }}</span>
        </slot>
      </p>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider
  },
  props: {
    vid: {
      type: String,
      default: ""
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    // must be included in props
    value: {
      type: null,
      default: ""
    },
    placeholder: {
      type: [String, Number],
      default: ""
    },
    labelPosition: {
      type: String,
      default: "on-border"
    },
    label: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: ""
    },
    prefix: {
      type: String,
      default: null
    },
    suffix: {
      type: [Boolean, String],
      default: null
    },
    expanded: {
      type: Boolean,
      default: true
    },
    hasCounter: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: [String, Number],
      default: null
    },
    type: {
      type: String,
      default: "text"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    validationMode: {
      type: String,
      default: "eager"
    },
    inputRef: {
      type: String,
      default: ""
    },
    step: {
      type: String,
      default: null
    },
    inputType : {
      type: String,
      default: "text",
    }
  },
  data: () => ({
    innerValue: ""
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value || this.value == 0 ) {
      this.innerValue = this.value;
    }
  },
  methods: {
    emitInput(e) {
      this.$emit("typing", e);
    },
    emitChange() {
      this.$emit("change", this.innerValue);
    },
    emitBlur(e) {
      this.$emit("blur", e);
    },
    emitFocus(e) {
      this.$emit("focus", e);
    },
    emitIconRightClick(e) {
      this.$emit("icon-right-click", e);
    }
  }
};
</script>
