import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const defaultState = {
  menu: true,
  barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
  barImage:
    "https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg",
  navigation: null,
  drawerLoading: false,
  title: "",

  right_drawer: {
    show: false,
    content: "",
    width: "600",
    options: {}
  },
  right_drawer_child1: {
    show: false,
    content: "",
    width: "600",
    options: {}
  },
  right_drawer_child2: {
    show: false,
    content: "",
    width: "600",
    options: {}
  },
  dialog: {
    width: 500,
    show: false,
    loading: false,
    persistent: false,
    fullscreen: false,
    hide_overlay: true,
    content: "",
    transition: ""
  },

  snackbar: {
    show: false,
    content: null,
    color: "info"
  }
};

const inBrowser = typeof window !== "undefined";
// if in browser, use pre-fetched state injected by SSR
const state =
  inBrowser && window.__INITIAL_STATE__
    ? window.__INITIAL_STATE__.page
    : defaultState;

export default {
  state,
  actions,
  mutations,
  getters
};
